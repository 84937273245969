import moment from 'moment';

export const SERVER = (process.env.NODE_ENV === 'production' && window.react?.REACT_APP_API_URL)
    ? window.react.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL;

export const API = {
    auth: {
        token: `${SERVER}driver/auth/`,
        refresh: `${SERVER}driver/auth/refresh/`,
    },
    user: { new_password: `${SERVER}user/password/set/:token/` },
    profile: `${SERVER}driver/user/`,
    dashboard: `${SERVER}driver/dashboard/:id/`,
    cars: {
        qr: `${SERVER}driver/car/`,
        single: `${SERVER}driver/car/:id/`,
        update: `${SERVER}driver/car-data/:id/`,
        dataSource: `${SERVER}driver/car-data/:id/fields-source/`,
        dropdowns: `${SERVER}driver/car-data/dropdown-maps/`,
        history: `${SERVER}driver/cars/`,
        booking: `${SERVER}driver/book/list/car/:id/`,
    },
    mileage: {
        list: `${SERVER}driver/mileage/list/:id/`,
        detail: `${SERVER}driver/mileage/:id/`,
        create: `${SERVER}driver/mileage/`,
        fields: `${SERVER}config/mileage_fields/`,
    },
    refuel: {
        base: `${SERVER}driver/refuel/`,
        list: `${SERVER}driver/refuel/list/:id/`,
        detail: `${SERVER}driver/refuel/:id/`,
        fuel_categories: `${SERVER}dashboard/fuel-map/`,
    },
    booking: {
        list: `${SERVER}driver/book/list/`,
        book: `${SERVER}driver/book/`,
        cars: `${SERVER}driver/book/search/`,
        bookId: `${SERVER}driver/book/:id/`,
    },
    journey: {
        customers: `${SERVER}driver/client/list/:id/`,
        base: `${SERVER}driver/client/mileage/`,
        detail: `${SERVER}driver/client/mileage/:id/`,
        location: `${SERVER}driver/google/location/`,
    },
    headers: {
        'Content-Type': 'application/json',
        'App-Version': '1.1.0',
        'Client-App': 'app',
    },
    fileHeaders: {
        'Content-Type': 'multipart/form-data',
        'App-Version': '1.1.0',
        'Client-App': 'app',
    },
};

export const BACKEND_DATETIME_FORMAT = moment.ISO_8601;
export const GOOGLE_CAPTCHA_SITE_KEY = '6Lc0n3kaAAAAACcNZy34NKWilMJRpdTXHF_4rIZp';
export const MAX_UPLOAD_BYTES = 9500000; // A bit under nginx limit
export const MAX_UPLOAD_STR = `${+(MAX_UPLOAD_BYTES / 1000000).toFixed(1)}MB`;
